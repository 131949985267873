<template>
  <v-container>
    <head-title text="Contabilidad"></head-title>
    <menu-grid :items="items"></menu-grid>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {
    HeadTitle: () => import("@/components/base/HeaderTitle"),
    MenuGrid: () => import("@/components/base/MenuGrid"),
  },
  data: () => ({
    permissions: [],
    items: [
      {
        title: "Registrar Compra",
        ico: "clipboard-check",
        to: { name: "accounting-register-buy" },
        permission: "_create_accountings_register_buy",
      },
      {
        title: "Proveedores",
        ico: "archive",
        to: { name: "accounting-provider_list" },
        permission: "_view_accountings_providers",
      },
      {
        title: "Pagos",
        ico: "receipt",
        to: { name: "accounting-payment-list" },
        permission: "_view_accountings_document_payment",
      },
      {
        title: "Recibos de Caja",
        ico: "file-invoice",
        to: { name: "accounting-cash-receipt-list" },
        permission: "_view_accountings_cash_receipt",
      },
      {
        title: "Facturas",
        ico: "file-invoice",
        to: { name: "accounting-invoices-list" },
        permission: "_view_accountings_invoice",
      },
      {
        title: "Cuentas Contables",
        ico: "sitemap",
        to: { name: "classification-list" },
        permission: "_view_accountings_invoice",
      },
    ],
  }),
};
</script>
